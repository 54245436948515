/*----------  Global Variables  ----------*/

let wWidth;
let wHeight;

/*----------  Document Ready  ----------*/

$(document).ready(() => {
	wWidth = $(window).width();
	wHeight = $(window).height();

	console.log('Ready!');
});

/*----------  Window Events  ----------*/

$(window).on('load resize', () => {
	wWidth = $(window).width();
	wHeight = $(window).height();
});